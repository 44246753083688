import { TextField } from 'lib/components/TextField';
import { ColumnButton } from 'lib/components/ColumnButton';
import React, { useState } from 'react';
import { OrderModel } from 'lib/model/objects/orderModel';
import LoadingState from 'components/LoadingState';
import { CouponModel } from 'lib/model/objects/couponModel';
import { Alert } from 'lib/components/Alert';
import CouponBadge from './CouponBadge';

type CouponsProps = {
  orderModel: OrderModel;
  version: number;
  invoiceLoading: boolean;
  couponLoading: boolean;
  coupons: CouponModel[] | null;
  couponError: string;
  onAddCoupon: (couponCode: string) => Promise<void>;
  onRemoveCoupon: (couponCode: string) => Promise<void>;
};

function Coupons({
  invoiceLoading,
  couponLoading,
  coupons,
  couponError,
  onAddCoupon,
  onRemoveCoupon
}: CouponsProps) {
  const [couponCode, setCouponCode] = useState('');
  return (
    <>
      <div className="grid grid-cols-2 gap-6">
        <div className="col-span-2">
          <TextField
            type="text"
            id="coupon-code-input"
            labelText="Discount Code"
            value={couponCode || ''}
            onChange={couponCode => setCouponCode(couponCode)}
          />
          <div className="mt-2 flex justify-end">
            {couponLoading && (
              <LoadingState
                isFullScreen={false}
                timeout={300}
                context={{
                  location: '/ads/place/order-summary'
                }}
              />
            )}
            {!couponLoading && (
              <ColumnButton
                type="button"
                buttonText="Apply Discount Code"
                secondary
                loading={couponLoading}
                disabled={invoiceLoading}
                onClick={() => {
                  const code = couponCode;
                  setCouponCode('');
                  void onAddCoupon(code);
                }}
              />
            )}
          </div>
          <div className="mt-2 flex justify-end">
            {!couponLoading &&
              !invoiceLoading &&
              coupons &&
              coupons.map(coupon => (
                <CouponBadge
                  key={coupon.id}
                  coupon={coupon}
                  onClose={() => onRemoveCoupon(coupon.modelData.code)}
                />
              ))}
          </div>
          {couponError && (
            <Alert id="coupon-error" status="error" title={couponError} />
          )}
        </div>
      </div>
    </>
  );
}
export default Coupons;
